<template>
  <footer id="footer" class="tw-bg-gray-900 tw-text-white">
    <div class="tw-mx-auto tw-max-w-7xl tw-px-12 tw-py-16">
      <div class="tw-grid tw-gap-12 sm:tw-grid-cols-2 lg:tw-grid-cols-4">
        <section
          class="tw-order-1 tw-flex tw-flex-col tw-items-center sm:tw-col-span-2 lg:tw-order-none lg:tw-items-start"
        >
          <img class="tw-max-w-[250px]" src="/logo.svg" />

          <PlayStoreLinks class="tw-mt-8 !tw-gap-6" />
        </section>

        <section>
          <h2 class="tw-text-white/70">
            <i18n-t scope="global" keypath="Menu" />
          </h2>

          <hr class="tw-my-4 tw-border-white/20" />

          <div class="tw-flex tw-flex-col tw-gap-4">
            <router-link active-class to="/">
              <i18n-t scope="global" keypath="Start-(footer)" />
            </router-link>

            <router-link active-class to="/upgrade">
              <i18n-t scope="global" keypath="Upgrade-(pro)" />
            </router-link>

            <a href="https://riserapp.com/support" target="_blank">
              <i18n-t scope="global" keypath="Support" />
            </a>

            <router-link active-class to="/about">
              <i18n-t scope="global" keypath="About" />
            </router-link>
          </div>
        </section>

        <section>
          <h2 class="tw-text-white/70">
            <i18n-t scope="global" keypath="Follow-Us" />
          </h2>

          <hr class="tw-my-4 tw-border-white/20" />

          <div class="tw-flex tw-flex-col tw-gap-4">
            <a href="https://www.facebook.com/riserbikeapp/" target="_blank">Facebook</a>
            <a href="https://www.instagram.com/riserapp/" target="_blank">Instagram</a>
            <a href="http://journal.riserapp.com" target="_blank">RISER Journal</a>
          </div>
        </section>
      </div>

      <div class="tw-mt-16 tw-px-4 tw-text-center tw-text-sm tw-text-white/80">
        <span class="tw-text-white/60">Copyright © {{ year }} RISER GmbH</span>

        <span>&nbsp;|&nbsp;</span>

        <router-link active-class to="/terms">
          <i18n-t scope="global" keypath="Terms of Service" />
        </router-link>

        <span>&nbsp;|&nbsp;</span>

        <router-link active-class to="/privacy">
          <i18n-t scope="global" keypath="Privacy Policy" />
        </router-link>

        <span>&nbsp;|&nbsp;</span>

        <router-link active-class to="/imprint">
          <i18n-t scope="global" keypath="Imprint" />
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const year = computed(() => new Date().getFullYear())
</script>
